import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Night Image is Blurry",
  "path": "/Frequently_Asked_Question/Night_Image_is_Blurry/",
  "dateChanged": "2018-03-15",
  "author": "Mike Polinowski",
  "excerpt": "Nothing to recognize in the night.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - Night Image is Blurry' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='Nothing to recognize in the night.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Night_Image_is_Blurry/' locationFR='/fr/Frequently_Asked_Question/Night_Image_is_Blurry/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <p><strong parentName="p">{`Q`}</strong>{`: Nothing to recognize in the night.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: A few days ago I bought the IN-5907HD, at first I was very impressed with the performance of the camera. But when it was night, I realize that I can not recognize anything.`}</p>
    <p>{`Blurring of moving objects always occurs when the lighting conditions force the camera to use longer exposure times. As soon as the exposure time becomes so long that the person can move a few centimeters between two shutters, the image will be blurry ("ghosting").`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "770px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2ac14080efd36de68011f42da79e492f/f4b77/Panasonic_MN34229_Motion-Blur.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1klEQVQY0yXQ3WrTUBwA8P+uTDZY26XnJOf0nDRNTtKThKbJ2jWrYxuik9bJOgt+XOmUTCjYgVN8CrF4Me9E76zOC7vqi0zwAwTfwF14pzIEfYMfP8CYU1oxdLOo0ULBCIK4d+Va51J/KV0FgCIijNmEVgzDRJjRktXZ3NroXr7Y7fl+CIRYhFiMifbKhVb73HBvf/fugBKz4rgAgBAlRhkhxpizoNFGc/nxaKRTXnGrjhCAEZd+stm7vtHpUeo8GT29N7wfhLHwfADI51FYa7Ta61jn8zm01es/f/HSk2EUL3pVDzBizdZaurwuRIh1Pp1+GA73PRm5XggAc3P5893tGzfv6DpXlfyDh4/Gr9+Uba+eNBY0DXTdJEZZyigIEunXj48/DgZ70q9XZQ0ACpquaTSsJc10ZWZGOXh2MDl6zy03bqSqOgsYc0Is2/aljFvp6snJjywb+EEi3H/sAjJImZas9OyabvCj6eTdZMotESVLijr7v8RkJduyqtv9q6env3duZcINK7YHALl8UTe4YZimKeJG+vnrp8PDtyXTDqLFM4oKmkYxZpw5qMiybPfPr5+3dzIZxLZTBYD5nIYwI8QktCxc+e37l1fjsSVklDQVRf0LLTlna2QxzXoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ac14080efd36de68011f42da79e492f/e4706/Panasonic_MN34229_Motion-Blur.avif 230w", "/en/static/2ac14080efd36de68011f42da79e492f/d1af7/Panasonic_MN34229_Motion-Blur.avif 460w", "/en/static/2ac14080efd36de68011f42da79e492f/5fbe9/Panasonic_MN34229_Motion-Blur.avif 770w"],
              "sizes": "(max-width: 770px) 100vw, 770px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2ac14080efd36de68011f42da79e492f/a0b58/Panasonic_MN34229_Motion-Blur.webp 230w", "/en/static/2ac14080efd36de68011f42da79e492f/bc10c/Panasonic_MN34229_Motion-Blur.webp 460w", "/en/static/2ac14080efd36de68011f42da79e492f/cf403/Panasonic_MN34229_Motion-Blur.webp 770w"],
              "sizes": "(max-width: 770px) 100vw, 770px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ac14080efd36de68011f42da79e492f/81c8e/Panasonic_MN34229_Motion-Blur.png 230w", "/en/static/2ac14080efd36de68011f42da79e492f/08a84/Panasonic_MN34229_Motion-Blur.png 460w", "/en/static/2ac14080efd36de68011f42da79e492f/f4b77/Panasonic_MN34229_Motion-Blur.png 770w"],
              "sizes": "(max-width: 770px) 100vw, 770px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2ac14080efd36de68011f42da79e492f/f4b77/Panasonic_MN34229_Motion-Blur.png",
              "alt": "Motion Blur",
              "title": "Motion Blur",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To counter this, you can once improve the illumination in the monitored area (external headlight), and / or position the camera so that the person must move directly to the camera to cross the area (the effect is always strongest when the person moves across the area).`}</p>
    <p>{`In the camera model IN-9008 Full HD, we have another sensor installed by the company Panasonic, which handles the situation a little differently. Here you have i.d.R. a much darker (often a little noisy) video image - this is accepted in order to keep the exposure time as low as possible and to resolve movements accordingly better. A longer discussion of the advantages and disadvantages of this technique can be found `}<a parentName="p" {...{
        "href": "https://forum.instar.de/forum/instar-kameras/in-8015-full-hd/39483-unsere-erfahrung-mit-der-in-8015-full-hd"
      }}>{`in our forum`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      